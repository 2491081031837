<template>
  <div>
    <field label="分类设置：" :asterisk="true" labelWidth="168px">
      <el-checkbox-group
        @change="handleCategoryChange"
        v-model="checkedCategoryLables"
      >
        <el-checkbox
          v-for="item in categories"
          :label="item.name"
          :key="item.id"
        >
        </el-checkbox>
      </el-checkbox-group>
    </field>
    <field label="分类排序：" :asterisk="true" labelWidth="168px">
      <VueDraggable class="categoryWrapper" v-model="checkedCategoies">
        <div class="category" v-for="item in checkedCategoies" :key="item.id">
          <img :src="item.mainPic" />
          <div>{{ item.name }}</div>
        </div>
      </VueDraggable>
    </field>
  </div>
</template>

<script>
import VueDraggable from 'vuedraggable'
import * as appletService from '@/api/applet'
import * as R from 'ramda'

export default {
  components: {
    VueDraggable
  },
  data () {
    return {
      categories: [],
      checkedCategoryLables: [],
      checkedCategoies: []
    }
  },
  created () {
    this.fetchCategories()
  },
  updated () {
    this.$emit('onChange', this.checkedCategoies)
  },
  methods: {
    async fetchCategories () {
      try {
        const res = await appletService.categoryList({ level: 1 })
        this.categories = res
        let checked = res.filter(o => o.checked)
        const byNum = R.comparator((a, b) => a.seqencing < b.seqencing)
        checked = R.sort(byNum, checked)
        this.checkedCategoies = checked
        this.checkedCategoryLables = checked.map(o => o.name)
      } catch (err) {
        // handle error
      }
    },
    handleCategoryChange (selected) {
      const checked = this.categories.filter(o => selected.includes(o.name))
      this.checkedCategoies = checked
    }
  }
}
</script>

<style scoped lang="scss">
.categoryWrapper {
  display: flex;
}

.category {
  & > div {
    text-align: center;
  }
  & > img {
    background-color: #d9e0f0;
    display: block;
    width: 60px;
    height: 60px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
  }
  margin-right: 40px;
}
</style>
