<template>
  <div class="container">
    <div class="card">
      <div class="title">基本信息</div>
      <field label="公告弹窗是否展示：" :asterisk="true" labelWidth="168px" :validation="validation.status">
        <el-radio-group v-model="formData.status">
          <el-radio :label="true">显示</el-radio>
          <el-radio :label="false">隐藏</el-radio>
        </el-radio-group>
      </field>
      <field label="公告弹窗图片：" labelWidth="168px" :validation="validation.imgUrl">
        <el-upload accept="image/*" :action="baseUrl" :headers="headers" :show-file-list="false" :on-success="onUploadSuccess">
          <img v-if="formData.imgUrl" :src="formData.imgUrl" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </field>
      <field label="首页标语内容：" :asterisk="true" labelWidth="168px" :validation="validation.title">
        <el-input v-model="formData.title" placeholder="请输入首页标语内容" style="width: 640px;"></el-input>
      </field>
      <field label="首页公告内容：" :asterisk="true" labelWidth="168px" :validation="validation.content">
        <el-input
          v-model="formData.content"
          type="textarea"
          :rows="3"
          placeholder="请输入首页公告内容"
          style="width: 640px;"
        ></el-input>
      </field>
      <field label="首页公告背景：" :asterisk="true" labelWidth="168px" :validation="validation.color">
        <el-color-picker v-model="formData.color"></el-color-picker>
      </field>
      <field label="首页分享图片：" labelWidth="168px">
        <div class="sharedBox">
          <el-upload
            accept="image/*"
            :action="baseUrl"
            :headers="headers"
            :show-file-list="false"
            :on-success="onUploadImgUrlSuccess"
            style="display: inline-block;"
          >
            <img v-if="sharedImgUrl" :src="sharedImgUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <el-button type="danger" icon="el-icon-delete" @click="clearSharedImg" class="deleteShared"></el-button>
        </div>
      </field>
    </div>

    <div class="card">
      <div class="title">Banner图片</div>
      <Banner />
    </div>
    <div class="card">
      <div class="title">分类</div>
      <Category @onChange="handleCategoryChange" />
    </div>

    <div class="card">
      <div class="title">板块</div>
      <Plate @onChange="handlePlateChange" />
    </div>
    <div class="footer">
      <el-button type="primary" @click="submit">保存</el-button>
    </div>
  </div>
</template>

<script>
import * as v from '@/utils/validator'
import validation from '@/mixins/validation'
import { baseURL } from '@/config'
import * as appletService from '@/api/applet'
import * as R from 'ramda'
import Plate from './plate'
import Category from './category'
import Banner from './banner'

const init = {
  status: false,
  imgUrl: null,
  title: '',
  content: ''
}

export default {
  components: {
    Plate,
    Category,
    Banner
  },
  mixins: [
    validation({
      rules: {
        imgUrl: [v.required('请选择公告弹窗图片')],
        title: [v.required('请输入首页标语内容')],
        content: [v.required('请输入首页公告内容')]
      },
      field: 'formData'
    })
  ],
  data () {
    return {
      baseUrl: `${baseURL}/api/file/v1/ajh`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token') || ''}`
      },
      formData: R.clone(init),
      notice: null,
      checkedCategories: null,
      checkedPlates: null,
      banners: [],
      sharedImgUrl: '' // 首页分享图片地址
    }
  },
  created () {
    this.fetchNotices()
    this.getSharedImg()
  },
  methods: {
    async fetchNotices () {
      try {
        const res = await appletService.getNotices()
        if (!R.isEmpty(res)) {
          this.notice = res[0]
          this.formData = R.pick(['title', 'imgUrl', 'content', 'status', 'color'], res[0])
        }
      } catch (err) {
        // handle error
      }
    },
    async submit () {
      try {
        await this.$validate()
        if (this.notice) {
          await appletService.updateNotice({
            ...this.formData,
            id: this.notice.id
          })
        } else {
          await appletService.createNotice(this.formData)
        }

        if (this.checkedCategories) {
          const postData = this.checkedCategories.map((o, index) => ({
            id: o.id,
            seqencing: index + 1
          }))
          await appletService.sortCategory(postData)
        }

        if (this.checkedPlates) {
          const postData = this.checkedPlates.map((o, index) => ({
            id: o.id,
            seqencing: index + 1,
            color: o.color
          }))
          await appletService.sortPlate(postData)
        }

        await appletService.saveSharedImg({ file: this.sharedImgUrl })

        this.$notify.success({ title: '更新设置成功' })
        this.fetchNotices()
      } catch (err) {
        this.$notify.error({ title: '更新设置失败' })
      }
    },
    handlePlateChange (plateData) {
      this.checkedPlates = plateData
    },
    handleCategoryChange (categoryData) {
      this.checkedCategories = categoryData
    },
    onUploadSuccess (res) {
      const { url } = res.body.data
      this.formData.imgUrl = url
    },
    onUploadImgUrlSuccess (res) {
      const { url } = res.body.data
      this.sharedImgUrl = url
    },
    async saveSharedImg () {
      try {
        await appletService.saveSharedImg({ file: this.sharedImgUrl })
        this.$notify.success({ title: '设置首页分享图片成功！' })
      } catch (error) {
        this.$notify.error({ title: '设置首页分享图片失败！' })
      }
    },
    clearSharedImg () {
      this.sharedImgUrl = ''
    },
    async getSharedImg () {
      const res = await appletService.getSharedImg()
      this.sharedImgUrl = res.file
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  text-align: left;
}
.card {
  background-color: white;
  padding: 40px;
  margin: 10px auto;
}

.title {
  color: #5b5b5bff;
  font-size: 20px;
  margin-bottom: 24px;

  &:before {
    content: "";
    background: #1d90fbff;
    width: 4px;
    height: 18px;
    display: inline-block;
    margin-right: 8px;
  }
}

.footer {
  margin-top: 20px;
  text-align: center;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
.sharedBox {
  display: inline-block;
  .deleteShared {
    width: 44px;
    height: 32px;
    vertical-align: bottom;
    margin-left: 20px;
  }
}

</style>
