<template>
  <div>
    <div class="container">
      <el-upload accept="image/*" :action="baseUrl" :headers="headers" :show-file-list="false" :on-success="onUploadSuccess">
        <i class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>

      <div class="banner">
        <div class="banner-item" v-for="(item, index) in banners" :key="item.imgUrl">
          <img :src="item.imgUrl" />
          <field label="背景色：" labelWidth="70px">
            <el-color-picker class="colorPicker" v-model="item.color"> </el-color-picker>
          </field>
          <field label="类型：" labelWidth="70px">
            <el-select v-model="item.type">
              <el-option v-for="type in types" :key="type.value" :label="type.name" :value="type.value"></el-option>
            </el-select>
          </field>
          <field label="板块：" labelWidth="70px" v-if="item.type === 1">
            <el-select v-model="item.jumpId">
              <el-option v-for="plate in plates" :key="plate.id" :label="plate.name" :value="plate.id"></el-option>
            </el-select>
          </field>
          <field label="类目：" labelWidth="70px" v-if="item.type === 2">
            <el-select v-model="item.jumpId">
              <el-option v-for="cate in categories" :key="cate.id" :label="cate.name" :value="cate.id"></el-option>
            </el-select>
          </field>
          <field label="图片：" labelWidth="70px" v-if="item.type === 3">
            <el-upload
              accept="image/*"
              :action="baseUrl"
              :headers="headers"
              :show-file-list="false"
              :on-success="e => uploadActivity(e, index)"
            >
              <img v-if="item.detailImgUrl" :src="item.detailImgUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </field>

          <span class="banner-item__close" @click="deleteBanner(item.id)">
            <i class="iconfont">&#xe618;</i>
          </span>
        </div>
      </div>
    </div>
    <el-button type="primary" @click="saveBanners" style="margin: 20px 0 0 142px;">保存</el-button>
  </div>
</template>

<script>
import { baseURL } from '@/config'
import * as appletService from '@/api/applet'
import { errorMessage } from '@/utils/validator'

export default {
  data () {
    return {
      baseUrl: `${baseURL}/api/file/v1/ajh`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token') || ''}`
      },
      types: [
        {
          name: '不跳转',
          value: 0
        },
        {
          name: '板块',
          value: 1
        },
        {
          name: '类目',
          value: 2
        },
        {
          name: '活动图片',
          value: 3
        }
      ],
      banners: [],
      categories: [], // 类目
      plates: [] // 板块
    }
  },
  created () {
    this.fetchBannerList()
    this.fetchCategories()
    this.fetchPlates()
  },
  methods: {
    async fetchBannerList () {
      try {
        const res = await appletService.bannerList()
        res.forEach(item => {
          if (!item.type) {
            item.type = 0
          }
          if (!item.jumpId) {
            item.jumpId = 0
          }
          if (!item.detailImgUrl) {
            item.detailImgUrl = ''
          }
        })
        this.banners = res
      } catch (err) {
        this.$notify.error('获取banner失败！')
      }
    },
    async deleteBanner (id) {
      try {
        await appletService.deleteBanner(id)
        this.fetchBannerList()
      } catch (err) {
        this.$notify.error('删除banner失败！')
      }
    },
    async onUploadSuccess (res, file) {
      const data = {
        imgUrl: res.body.data.url,
        color: '#FFFFFF',
        type: 0,
        jumpId: 0,
        detailImgUrl: ''
      }
      // this.banners.unshift(data)
      await appletService.addBanner(data)
      this.fetchBannerList()
    },
    async fetchCategories () {
      const res = await appletService.categoryList({ level: 1 })
      this.categories = res.filter(item => item.checked)
    },
    async fetchPlates () {
      const res = await appletService.plateList({ level: 1 })
      this.plates = res.filter(item => item.checked)
    },
    async saveBanners () {
      try {
        this.banners.forEach(item => {
          delete item.createdDate
          delete item.lastModifiedDate
        })
        await appletService.savaBanners(this.banners)
        this.$notify.success('banner保存成功！')
        this.fetchBannerList()
      } catch (error) {
        const message = errorMessage(error) || 'banner保存失败！'
        this.$notify.error(message)
      }
    },
    uploadActivity (res, index) {
      const url = res.body.data.url
      this.$set(this.banners[index], 'detailImgUrl', url)
    }
  }
}
</script>

<style scoped lang="scss">
.banner {
  display: flex;
  flex-wrap: wrap;

  &-item {
    position: relative;
    width: 200px;
    margin: 0 20px 10px 10px;
    padding: 20px 20px 0;
    background-color: #f5f5f5;
    & > img {
      border: 1px solid #eeeeee;
      width: 200px;
      height: 100px;
      margin-bottom: 10px;
      object-fit: cover;
      object-position: center;
    }

    &__close {
      position: absolute;
      cursor: pointer;
      color: #e3e3e3;
      top: 2px;
      right: 0;
      width: 20px;
      height: 20px;
      font-size: 16px;
      text-align: center;
      &:hover {
        color: #505162;
      }
    }
  }
}

.container {
  display: flex;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}

.colorPicker {
  padding-top: 5px;
}
</style>
