<template>
  <div>
    <field label="板块设置：" :asterisk="true" labelWidth="168px">
      <el-checkbox-group @change="handlePlateChange" v-model="checkedPlateLables">
        <el-checkbox
          v-for="item in plates"
          :label="item.name"
          :key="item.id"
        >
        </el-checkbox>
      </el-checkbox-group>
    </field>
    <field label="板块排序：" :asterisk="true" labelWidth="168px">
      <VueDraggable v-model="checkedPlates">
        <div
          class="plate"
          v-for="item in checkedPlates"
          :key="item.id"
        >
          <img :src="item.mainPic" />
          <div>{{ item.name }}</div>
        </div>
      </VueDraggable>
    </field>
    <field label="板块背景：" :asterisk="true" labelWidth="168px">
      <el-color-picker v-model="bgColor" @change="colorChange"></el-color-picker>
    </field>
  </div>
</template>

<script>
import VueDraggable from 'vuedraggable'
import * as appletService from '@/api/applet'
import * as R from 'ramda'

export default {
  components: {
    VueDraggable
  },
  data () {
    return {
      plates: [],
      checkedPlateLables: [],
      checkedPlates: [],
      bgColor: null
    }
  },
  created () {
    this.fetchPlates()
  },
  updated () {
    this.$emit('onChange', this.checkedPlates)
  },
  methods: {
    async fetchPlates () {
      try {
        const res = await appletService.plateList({ level: 1 })
        this.plates = res
        let checked = res.filter(o => o.checked)
        const byNum = R.comparator((a, b) => a.seqencing < b.seqencing)
        checked = R.sort(byNum, checked)
        this.checkedPlates = checked
        this.checkedPlateLables = checked.map(o => o.name)
        this.bgColor = checked.length > 0 ? checked[0].color : null
      } catch (err) {
        // handle error
      }
    },
    handlePlateChange (selected) {
      const checked = this.plates.filter(o => selected.includes(o.name))
      checked.forEach(item => { item.color = this.bgColor })
      this.checkedPlates = checked
    },
    colorChange (value) {
      this.checkedPlates.forEach(item => { item.color = value })
      this.$emit('onChange', this.checkedPlates)
    }
  }
}
</script>

<style scoped lang="scss">

.plate {
  position: relative;
  &>div{
    position: absolute;
    bottom: 5px;
    left: 0;
    height: 20px;
    width: 200px;
    text-align: center;
    background-color: #000;
    opacity: 0.6;
    color: white;
  }
  &> img {
    background-color: #D9E0F0;
    width: 200px;
    height: 100px;
    object-fit: cover;
    object-position: center;
  }
}

</style>
