import axios from './axios'

export const createNotice = (form) => axios.post('/management/notice', form)
export const updateNotice = (form) => axios.put('/management/notice', form)
export const getNotices = () => axios.get('/management/notice/list')
export const bannerList = () => axios.get('/management/banner/list')
export const addBanner = (form) => axios.post('/management/banner', form)
export const updateBanner = (form) => axios.put('/management/banner', form)
// 一次性保存banner
export const savaBanners = (data) => axios.put('/management/banner/list', data)
export const deleteBanner = (id) => axios.put(`/management/banner/${id}`)
export const categoryList = (params) => axios.get('/management/categories/home/list', { params })
export const sortCategory = (form) => axios.put('/management/categories/sort-setting', form)
export const plateList = (params) => axios.get('/management/plate/home/list', { params })
export const sortPlate = (form) => axios.put('/management/plate/setting', form)
// 保存首页分享图片
export const saveSharedImg = (data) => axios.put('/management/home/pic', data)
// 获取首页分享图片
export const getSharedImg = () => axios.get('/management/home/pic')
